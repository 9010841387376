main {
  height: 100%;
  width: 100%;
  min-width: 320px;
}

.about-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  width: 100%;
  min-height: 650px;
  max-height: 800px;
  background-image: url("../../images/AboutUs/aboutHeroImgweb.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  z-index: 0;
}

.about-hero-title {
  height: fit-content;
  width: 100%;
  background-color: #0f1a35;
  color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0 2rem 0;
  position: relative;
  z-index: 0;
  top: 5rem;
}

.about-info {
  background-color: #eaf4fa;
  border-radius: 15px;
  width: 70%;
  max-width: 1200px;
  height: fit-content;
  padding: 2rem 2rem 2rem 2rem;
  position: relative;
  text-align: center;
  margin: 0 auto;
  top: 5rem;
}

.trust {
  text-align: center;
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 10rem 5rem 10rem 5rem;
}

#leftDesignabout img {
  position: absolute;
  left: 0;
  z-index: -1;
  margin-top: -5rem;
}
#rightDesignabout img {
  position: absolute;
  right: 0;
  z-index: -1;
  margin-top: -10rem;
}

/* Animate AboutUs Headings */

.aboutUsAnimateHeading{
	-webkit-animation: tracking-in-expand-fwd 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	animation: tracking-in-expand-fwd 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@-webkit-keyframes tracking-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px);
            transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px);
            transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}







/* Animate AboutUs Subheadings */


.aboutUsAnimateSubHeading{
  -webkit-animation: scale-in-hor-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: scale-in-hor-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}




@media screen and (max-width: 1300px) {
  .trust #leftDesignabout img {
    width: 200px;
    opacity: 1;
  }
  .trust #rightDesignabout img {
    width: 200px;
  }
}

@media screen and (max-width: 1024px) {
  .about-hero {
    gap: 1rem;
  }
  .hero-section #leftDesignabout img {
    width: 200px;
  }
  .hero-section #rightDesignabout img {
    width: 200px;
  }
}

.about-info h2 {
  margin: 0 0 25px 0;
}

.about-grp-pic {
  height: fit-content;
  background-color: #eaf4fa;
}

.grp-pic-content {
  margin: 0 auto;
  padding: 45px;
  width: 80%;
}

.grp-pic-content img {
  margin: 0 auto;
  display: block;
  width: max-content;
  max-width: 100%;
}

@media screen and (max-width: 768px) {
  .grp-pic-content {
    padding: 25px 5%;
    width: 90%;
  }
  .about-hero-title {
    text-align: center;
    padding-left: 0;
  }
  .about-info {
    top: 3rem;
  }
  .about-hero {
    gap: 3rem;
  }
  .trust {
    padding: 7rem 10%;
  }
}

@media screen and (max-width: 480px) {
  .grp-pic-content {
    padding: 15px 2.5%;
    width: 95%;
  }
  .trust {
    padding: 5rem 10%;
  }
  .about-info {
    top: 3rem;
  }
}

/* Values Section */

.about-hero-title-values {
  height: fit-content;
  width: 100%;
  background-color: #0f1a35;
  color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0 2rem 0;
  position: relative;
  z-index: 0;
}

.values-section {
  height: 80vh;
  max-height: 800px;
  padding: auto 0px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}

.values-content {
  max-width: 1200px;
  width: 80%;
  padding: 45px 10px;
  margin: 10px auto;
}

.grid-container {
  margin: 30px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
}
.values-header-future {
  text-align: justify;
}

.tile {
  background-color: #eaf4fa;
  box-shadow: 4px 4px 2px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
}

.values-header {
  font-weight: 700;
}

.values-header > h1 {
  display: inline-block;
}

.values-body {
  margin: 5px 10px 0 10px;
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: auto;
  }

  .tile {
    padding: 5px 10px;
  }

  .values-content {
    margin: 0 auto;
  }
}

@media (max-width: 425px) {
  .about-hero {
    gap: 0.5rem;
  }
  .trust {
    padding: 3rem 2rem 3rem 2rem;
  }
  .trust #rightDesignabout img {
    display: none;
  }

  .about-hero-title {
    top: 3rem;
  }
  .about-info {
    top: 3rem;
  }
}

/* Director's Section */

.director-section {
  background-color: #eaf4fa;
}

.director-content {
  max-width: 1200px;
  width: 80%;
  padding: 45px;
  margin: 10px auto;
}

.director-content h2 {
  margin: 20px 0;
}

.director-content h3 {
  font-weight: 600;
}

.director-info {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  min-height: 300px;
}

.director-img {
  width: 400px;
  height: 250px;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  margin: 20px;
}

#vinay-img {
  background-image: url("../../images/AboutUs/aboutMDImgweb.webp");
}

#vandana-img {
  background-image: url("../../images/AboutUs/aboutDImgweb.webp");
}

.director-body {
  width: 70%;
  padding: 25px;
  margin: auto;
}

.director-body p {
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .director-content {
    padding: 20px;
  }

  .director-info {
    flex-direction: column;
  }

  .director-body {
    margin: 0 auto;
    padding: 20px 10px;
    width: 85%;
  }

  .director-body p {
    font-size: 14px;
  }

  .director-img {
    max-width: 1200px;
    width: 100%;
    min-height: 150px;
    height: 40vh;
    background-position: center;
    margin: 0;
  }

  .vandana-box {
    flex-direction: column-reverse;
  }
}

/* Social Foundation */

.social-foundation {
  padding: auto 0px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}

.socialFoundationLogo img{
  max-width: 25rem;
  width: 100%;
}
.social-content {
  max-width: 1200px;
  width: 60%;
  padding: 0 10px 30px 10px;
  margin: 0 auto;
  text-align: center;
  margin-top: 30px;
}

.social-content > img {
  width: 15rem;
  margin-bottom: 0.5rem;
}

.social-content > p {
  line-height: 30px;
}

@media screen and (max-width: 768px) {
  .social-content {
    width: 70%;
    margin-top: 20px;
    padding: 15px 10px;
  }
  .social-content > img {
    width: 10rem;
  }

  .social-content > p {
    line-height: 25px;
  }
}

@media screen and (max-width: 480px) {
  .social-content {
    width: 90%;
    margin-top: 10px;
    padding: 10px;
  }
  .social-content > p {
    line-height: 20px;
  }

  #rightDesignabout {
    display: none;
  }
}

.social-content button {
  margin: 10px 0;
}

.fullMission {
  width: 100%;
  padding: 1.5rem 0;
}

.textMission {
  text-align: center;
  color: #0f1a35;
  padding: 30px 0 0 0;
}

.blocksMission {
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: center;
  margin-top: 30px;
}

.missionBlock {
  width: 30em;
  background-color: #eaf4fa;
  margin-left: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.2);
}

.visionBlock {
  width: 30em;
  background-color: #eaf4fa;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.2);
}

.mvText {
  font-size: 22px;
  font-weight: 500;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
  color: #0f1a35;
}

.mainMVText {
  font-size: 18px;
  font-weight: 400;
  margin: 0 20px 20px 20px;
}

.buttonLearnMore {
  padding-top: 25px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .textMission {
    font-size: 26px;
  }
  .mvText {
    font-size: 16.5px;
  }

  .mainMVText {
    font-size: 15px;
    margin: 0 15px 15px 15px;
  }
}

@media screen and (max-width: 480px) {
  .textMission {
    font-size: 24px;
  }
  .mvText {
    font-size: 14.5px;
  }

  .mainMVText {
    font-size: 12px;
    margin: 0 8px 8px 8px;
  }
}

@media screen and (max-width: 600px) {
  .blocksMission {
    display: block;
  }

  .missionBlock {
    margin-right: 20px;
    padding: 0.5em;
    width: auto;
  }
  .visionBlock {
    margin-left: 20px;
    padding: 0.5em;
    width: auto;
  }
}

/* Breakpoints not required for 768px and 1200 */

.event-img-card {
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}

.event-img-card img {
  border-radius: 10px;
  height: 500px;
  width: auto;
  object-fit: fill;
  background-position: center;
}

@media (max-width: 1023px) {
  .event-img-card {
    height: 400px;
  }

  .event-img-card img{
    height: 400px;
  }
}

@media (max-width: 767px) {
  .event-img-card {
    height: 300px;
  }
  .event-img-card img{
    height: 300px;
  }
  .rec-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px !important;
    min-width: 25px !important;
    width: 25px !important;
    max-height: 25px !important;
    line-height: 0 !important;
  }
}

@media (max-width: 479px) {
  .event-img-card {
    height: 200px;
  }
  .event-img-card img{
    height: 200px;
  }
}

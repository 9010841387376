.copyrightMain{
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    margin-top: 10px;
    margin-left: 3em;
    margin-right: 3em; 
}
.copyrightMain p{
    font-weight: 500;
    font-size: 13px;
    color: #0F1A35;
}

.socialIcons{
    display: flex;
    flex-direction: row;
    gap: 1em;

}

.socialIcons :any-link{
    color: #0F1A35;
}


.socialIcons :any-link :hover{
    color: #97BAC2;
}

@media(max-width: 500px){
    .copyrightMain{
        display: block;
        text-align: center;
    }
    .socialIcons{
        margin-top: 10px;
        justify-content: center;
    }

}

.product-img-card{
    height: 200px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.product-img-card img{
    width: 225px;
}

.right-products.product-img-card{
    background-color: white;
}

.icCrcs{
    background-color: white !important;
    color: #0F1A35;
}

@media (max-width: 767px) {
    .product-carousel{
        width: 100vw;
        margin: auto 0;
    }

    .product-img-card img{
        width: 180px;
    }
}
a{
    color: white;
}
.mainFooter h3{
    font-weight: 500;
    font-size: 12px;
}

.mainFooter p{
    font-weight: 200;
    font-size: 13px;
}

.mainFooter{
    background-color: #0F1A35;
}

.eldoLogo img{
    width: 155px;
    height: 36px;
    margin: 1rem auto;
    margin-left: 1rem;
}
.line{
    border: 1px solid white;
}

.footerText{
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: space-between;
    margin-left: 4rem;
    margin-right: 4rem;
    margin-top: 50px;
}

.footerMenu li{
    list-style: none;
    margin-bottom: 15px;

}

.address{
    font-weight: 500;
}

.addressText{
    display: flex;
    flex-direction: row;
    gap: 1rem;
}


.contactText{
    display: flex;
    flex-direction: row;
    gap: 1rem;  
}

/* Mobile Phones - 768 */
@media(max-width: 1176px){
    .eldoLogo{
        text-align: center;
    }
    .footerText{
        display: block;
    }
    .footerMenu ul {
        display: flex;
        flex-direction: row;
        gap: 4em;
        justify-content: center;
        margin-bottom: 20px;
    }
    .footerAddress {
        text-align: center;
        margin-bottom: 20px;
    }
    .addressText{
        justify-content: center;
        
    }

    .footerContact{
        text-align: center;
    }

    .contactText{
        justify-content: center;

    }



}

/*500px*/

@media(max-width:500px){
    .eldoLogo{
        text-align: center;
    }

    .footerMenu ul {
        display: flex;
        flex-direction: row;
        gap: 1.5em;
        justify-content: center;
        margin-bottom: 20px;
    }
}
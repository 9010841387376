.fullpage {
  max-width: 1024px;
  margin: 0 auto 10px auto;
}

.heading {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 55px 0;
  background: #0f1a35;
}

.heading-text {
  width: 100%;
  color: #fff;
  font-size: 42px;
  font-weight: 700;
}

.page-body {
  padding: 10px 0;
}

.author span {
  color: blue;
  font-weight: 500;
}

.date {
  color: #7a7a7a;
}

.time {
  border-radius: 10px;
  background-color: #70b4dd;
  color: white;
  display: inline-block;
  width: fit-content;
  padding: 5px 10px;
  margin: 5px 0px;
}

.picture {
  width: 100%;
  height: fit-content;
  margin: 10px auto;
}

.picture img {
  border-radius: 15px;
  object-fit: cover;
  width: 100%;
  max-height: 400px;
}

.one-blog-content pre {
  text-align: justify;
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 32px;
  font-size: 18px;
}

@media (max-width: 1024px) {
  .heading-text {
    font-size: 36px;
  }

  .page-body {
    padding: 15px;
  }
}

@media (max-width: 768px) {
  .heading-text {
    font-size: 32px;
  }

  .one-blog-content pre {
    font-size: 16px;
  }
}

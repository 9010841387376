main {
  height: 100%;
  width: 100%;
  min-width: 320px;
}

#leftDesign img {
  position: absolute;
  left: 0;
}
#rightDesign img {
  position: absolute;
  right: 0;
}

.hero-section {
  text-align: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow-y: visible;
  margin-top: 1rem;
}

.hero-text {
  width: 60vw;
  white-space: nowrap;
  margin: 0px auto;
}

.hero-text span {
  color: goldenrod;
}

.hero-subtitle h3 {
  letter-spacing: 2px;
}

.btn-space {
  margin-top: 3vh;
}

.parentImage {
  display: flex;
  justify-content: center;
}

.hero-img {
  height: auto;
}

.hero-img img {
  max-width: 1500px;
  width: 50%;
  height: auto;
  margin-top: 10px;
}

.hero-text-img img {
  max-width: 1500px;
  width: 30%;
  height: auto;
}

/* Health Solution Text animation */

.animateTitleHero{
	-webkit-animation: tracking-in-expand-fwd 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	animation: tracking-in-expand-fwd 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@-webkit-keyframes tracking-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px);
            transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px);
            transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}

/* Animate Subheading */

.animateSubHeading{
  -webkit-animation: scale-in-hor-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: scale-in-hor-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}




/* 20YearsLogo Animation */
/* .animate20Years{
  -webkit-animation: scale-in-ver-top 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-in-ver-top 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
 @-webkit-keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
} */


.what-we-do {
  background-color: #eaf4fa;
  text-align: center;
  margin: 0px auto;
  padding: 2rem 0px;
}

.what-we-do p {
  width: 60vw;
  margin: 1.8rem auto;
}

.year20 img {
  margin: auto;
  width: 50%;
  max-width: 400px;
}

.auto-carousel-section {
  text-align: center;
}

.auto-carousel-section h2 {
  padding: 30px 0 20px 0;
}

.product-section {
  background-color: #eaf4fa;
  text-align: center;
}

.textMission{
  padding: 35px 0px 20px 0px;
}

.blog-section h2 {
  text-align: center;
}

.blog-section h2 {
  padding: 30px 0 20px 0;
}

.product-section > a > button {
  margin: 45px 0px;
}

.home-blog-btn {
  width: fit-content;
  margin: 45px auto;
}

.products {
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  width: 80%;
  margin: 0 auto;
}

.product-section h2 {
  padding: 30px 0 30px 0;
}

@media screen and (max-width: 1300px) {
  .missionVission #leftDesign img {
    width: 200px;
    z-index: -1;
    opacity: 1;
  }
  .missionVission #rightDesign img {
    width: 200px;
    z-index: -1;
  }
}

@media screen and (max-width: 1024px) {
  .hero-section #leftDesign img {
    width: 200px;
  }
  .hero-section #rightDesign img {
    width: 200px;
  }
  .home-blog-btn {
    margin: 30px auto;
  }
}

@media screen and (max-width: 768px) {
  .hero-text {
    width: 80vw;
  }

  .hero-text h1 {
    font-size: 36px;
  }

  .hero-subtitle p {
    font-size: 18px;
    line-height: 1.4em;
  }

  .hero-img img {
    width: 60%;
    margin-right: auto;
    margin-top: 10px;
  }
  .hero-text-img img {
    width: 35%;
  }

  .what-we-do h2 {
    font-size: 26px;
  }

  .what-we-do p {
    font-size: 15px;
    width: 80vw;
  }

  .year20 img {
    margin: 2% auto;
    width: 50%;
  }

  .blog-section h2 {
    font-size: 26px;
    padding: 35px 0px;
  }

  .hero-section #leftDesign img {
    width: 200px;
    z-index: -1;
  }
  .hero-section #rightDesign img {
    width: 200px;
    z-index: -1;
  }
  .home-blog-btn {
    margin: 20px auto;
  }
}

@media screen and (max-width: 480px) {
  .hero-text {
    width: 100vw;
  }

  .hero-text h1 {
    font-size: 32px;
  }

  .hero-subtitle h3 {
    font-size: 14.5px;
  }

  .hero-img img {
    width: 70%;
    margin-top: 20px;
  }

  .hero-text-img img {
    width: 55%;
  }

  .what-we-do {
    padding: 1rem 0px;
  }

  .what-we-do h2 {
    font-size: 24px;
  }

  .what-we-do p {
    margin: 1rem auto;
    font-size: 12px;
    width: 90vw;
  }

  .year20 img {
    margin: 2% auto;
    width: 50%;
  }

  .blog-section h2 {
    font-size: 24px;
    padding: 25px 0px;
  }
  .hero-section #leftDesign img {
    width: 150px;
    z-index: -1;
  }
  .hero-section #rightDesign img {
    width: 150px;
    z-index: -1;
  }
}

@media screen and (max-width: 1300) {
  .missionVission #leftDesign img {
    width: 200px;
    z-index: -2;
  }
  .missionVission #rightDesign img {
    width: 200px;
    z-index: -2;
  }
}
.product-box{
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
    width: 300px;
}

.content{
    padding: 10px 10px 2px 10px;
}

.prod-img{
    height: 280px;
    width: 280px;
}

.product-box img{
    border-radius: 6%;
    width: 100%;
    max-width: 300px;
    max-height: 300px;
    height: 100%;
    object-fit: cover;
}

.product-box h3{
    font-weight: 500;
    font-size: 18px;
    display: inline;
}

.sub-content{
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sub-content button{
    font-size: 14px;
    margin: 0px;
}

@media screen and (max-width: 1023px){
    .product-box{
        margin-bottom: 5px;
        width: 250px;
    }
    
    .content{
        padding: 5px 5px 1px 5px;
    }

    .prod-img{
        height: 240px;
        width: 240px;
    }
    
    .product-box img{
        border-radius: 6%;
        width: 100%;
        max-width: 280px;
        max-height: 280px;
        height: 100%;
        object-fit: cover;
    }
    
    .product-box h3{
        font-weight: 500;
        font-size: 14px;
        display: inline;
    }
    
    .sub-content{
        margin: 5px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .sub-content button{
        font-size: 12px;
        margin: 0px;
    }
}

@media screen and (max-width: 767px){
    .product-box{
        margin-bottom: 5px;
        max-width: 200px;
    }
    
    .content{
        padding: 5px 5px 1px 5px;
    }

    .prod-img{
        height: 190px;
        width: 190px;
    }
    
    .product-box img{
        border-radius: 6%;
        width: 100%;
        max-width: 180px;
        max-height: 180px;
        height: 100%;
        object-fit: cover;
    }
    
    .product-box h3{
        font-weight: 500;
        font-size: 12px;
        display: inline;
    }
    
    .sub-content{
        margin: 5px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .sub-content button{
        font-size: 10px;
        margin: 0px;
    }
}
.events-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  width: 100%;
  height: 90vh;
  background-image: url("../../images/Events/eventsHeroImg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  z-index: 0;
}

.events-hero-title {
  height: fit-content;
  width: 100%;
  background-color: #0f1a35;
  color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0 2rem 0;
  position: relative;
  z-index: 0;
  top: 5rem;
}

.event-content {
  padding: 50px;
}

.event-content h2 {
  width: auto;
  margin: 10px auto;
  text-align: center;
}

.gallery {
  background-color: #eaf4fa;
}

.conference {
  background-color: #eaf4fa;
}

.candid-img-card {
  height: 250px;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.candid-img-card img {
  border-radius: 25px;
  padding: 0 10px;
  height: 225px;
  width: auto;
  object-fit: fill;
  background-position: center;
}


/* Animation Subheadings */
.eventsAnimateHeading{
  -webkit-animation: tracking-in-expand-fwd 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	animation: tracking-in-expand-fwd 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}


@-webkit-keyframes tracking-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px);
            transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px);
            transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}


.eventsAnimateSubHeading{
  -webkit-animation: scale-in-hor-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: scale-in-hor-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}






@media (max-width: 1023px) {
  .event-content {
    padding: 30px;
  }
}
@media (max-width: 767px) {
  .event-content {
    padding: 20px;
  }
}
@media (max-width: 479) {
  .event-content {
    padding: 10px;
  }
}

.blog-box {
  background-color: #eaf4fa;
  max-width: 325px;
  min-height: 430px;
  height: fit-content;
  border-radius: 15px;
  margin: 10px 15px 0px 15px;
  box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.3);
}

.blog-content {
  height: inherit;
  width: 300px;
  padding: 12.5px 12.5px 0px 12.5px;
  margin: 0 auto;
}

.blog-box img {
  border-radius: 15px;
  width: 300px;
  height: 250px;
  margin: 0px auto;
  object-fit: cover;
}

.blog-img{
  width: 300px;
  height: 250px;
}

.blog-box span {
  border-radius: 10px;
  background-color: #70b4dd;
  color: white;
  display: inline-block;
  width: fit-content;
  padding: 5px 10px;
  margin: 5px 0px;
}

.blog-box h3 {
  font-weight: 500;
  height: 26px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 22px;
  max-width: 300px;
  overflow: hidden;
  margin-top: 5px;
}

.blog-body {
  height: 70px;
  width: 100%;
  font-size: 12px;
  margin: 5px 0;
}

.blog-page-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1050px) {
  .blog-box h3 {
    font-size: 20px;
  }

  .blog-box span {
    font-size: 14px;
  }

  .blog-box {
    max-width: 300px;
    min-height: 350px;
  }

  .blog-body{
    height: 70px;
  }

  .blog-content {
    height: inherit;
    width: 280px;
    padding: 10px 10px 0px 10px;
    margin: 0 auto;
  }

  .blog-box img{
    width: 280px;
    height: 230px;
  }

  .blog-img{
    width: 280px;
    height: 230px;
  }
}
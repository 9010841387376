.brandLogo img {
  display: inline-block;
  width: 234px;
  height: 55.48px;
}

.nav {
  height: 60px;
  z-index: 3;
  display: flex;
  align-items: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 1rem;
  color: black;
  margin: 0 auto 10px auto;
  max-width: 100rem;
}

.nav-menu {
  margin-left: auto;
}

.nav-menu ul {
  display: flex;
  padding: 0;
}
.nav-menu li {
  list-style-type: none;
  margin: 0 1.8rem;
}
.nav-menu li > a {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  display: block;
  width: 100%;
  color: inherit;
  border-radius: 100em;
  padding: 12px;
  transition: all .1s;
}

.nav-menu li > a:hover{
  color: #0f1a35;
  background-color: #0f1a3522;
  font-weight: 700;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #EAF4FA;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 1vw;
  transform: translateY(-50%);
  display: none;
}

@media screen and (max-width: 1024px) {
  .brandLogo img {
    display: inline-block;
    width: 216px;
    height: 51.22px;
  }
  .hamburger {
    display: block;
  }

  .nav-menu ul {
    position: absolute;
    margin-top: 20px;
    top: 60px;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: white;
    border-top: 2px solid black;
  }
  .nav-menu li {
    background-color: #EAF4FA;
    text-align: center;
    margin: 0;
  }
  .nav-menu li a {
    color: black;
    width: 100%;
    padding: 1.5rem 0;
  }
  .nav-menu li:hover {
    background-color: #fff;
  }

  .nav-menu ul {
    display: none;
  }

  .nav-menu.expanded ul {
    display: block;
  }
}

@media screen and (max-width: 1024px) {
  .brandLogo img {
    display: inline-block;
    width: 198px;
    height: 46.95px;
  }
}

@media screen and (max-width: 1024px) {
  .brandLogo img {
    display: inline-block;
    width: 185.4px;
    height: 43.91px;
  }
}
.contact-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  width: 100%;
  height: 90vh;
  max-height: 800px;
  background-image: url("../../images/ContactUs/contactHeroImg.webp");
  background-size:cover;
  background-repeat:no-repeat;
  background-position:right center;
  position: relative;
  z-index: 0;
}

.contactHeroTitle {
  height: fit-content;
  width: 100%;
  background-color: #0f1a35;
  color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0 2rem 0;
  position: relative;
  z-index: 0;
  top: 35%;
}

.career-with-us {
  height: fit-content;
  max-height: 1024px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 40px 0;
}

.career-content {
  margin: auto;
  width: 80%;
  text-align: center;
}

.career-grids {
  max-width: 1200px;
  margin: 20px auto;
  background-color: #eaf4fa;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 2.5px 5px 10px rgba(0, 0, 0, 0.5);
}

.career-grid-container-1 {
  display: grid;
  grid-template-columns: 200px 300px 200px;
  grid-template-rows: 200px;
  gap: 100px;
  justify-content: center;
  margin: 10px 0;
}

.career-grid-container-2 {
  display: grid;
  grid-template-columns: 300px 300px;
  grid-template-rows: 200px;
  gap: 100px;
  justify-content: center;
  margin: 10px 0;
}

.career-tile {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.career-header img {
  height: 100px;
}

.career-body {
  margin-top: 5px;
}

.contactAnimateHeading{
  -webkit-animation: tracking-in-expand-fwd 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	animation: tracking-in-expand-fwd 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;

}


@-webkit-keyframes tracking-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px);
            transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px);
            transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}


/* Animate SUbheading */

.contactAnimateSubHeading{
  -webkit-animation: tracking-in-expand-fwd 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	animation: tracking-in-expand-fwd 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}


@-webkit-keyframes tracking-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px);
            transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px);
            transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}


@media (max-width: 1024px) {
  .career-grid-container-1 {
    grid-template-columns: 150px 250px 150px;
    gap: 50px;
  }

  .career-grid-container-2 {
    grid-template-columns: 200px 200px;
    gap: 50px;
  }
}

@media (max-width: 768px) {
  .career-with-us {
    margin: 10px 0;
  }

  .career-grid-container-1 {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    margin-bottom: 50px;
  }

  .career-grid-container-2 {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    margin-top: 50px;
    margin-bottom: 25px;
  }

  .career-header img {
    height: 80px;
  }
}

@media (max-width: 480px) {
  .career-with-us {
    margin: 0;
  }

  .career-grids {
    padding: 10px;
    margin: 20px 0;
  }

  .career-grid-container-1 {
    margin-bottom: 35px;
    gap: 35px;
  }

  .career-grid-container-2 {
    margin-top: 35px;
    gap: 35px;
  }

  .career-header img {
    height: 60px;
  }
}

.contact-career {
  min-height: 400px;
  max-height: 1024px;
  background-color: #eaf4fa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-career-content {
  width: 80%;
  max-width: 1200px;
  margin: 20px auto;
}

/* Contact Form */
.full-contact {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.contact-form-content {
  position: relative;
  min-height: 600px;
  width: 80%;
  max-width: 1000px;
  margin: 50px auto;
}

.first-box {
  position: absolute;
  display: inline-block;
  margin-top: 40px;
  width: 30.4%;
  background-color: #0f1a35;
  color: white;
  z-index: 3;
  height: 500px;
  padding: 20px 5.5% 0;
  box-shadow: 0px 4px 36px 6px rgba(0, 0, 0, 0.25);
}

.first-box h3 {
  font-size: 28px;
  margin-left: 22px;
  margin-top: 8px;
}

.first-box p {
  font-size: 16px;
  font-weight: 400;
}

@media (max-width: 1024px) {
  .first-box p {
    font-size: 12px;
  }
}

.second-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  z-index: 1;
  right: 0px;
  width: 88%;
  max-width: 1200px;
  height: 600px;
  background-color: #eaf4fa;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

.second-box-content {
  margin-left: 31%;
  width: 61.7%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.second-box-content > h2 {
  display: block;
  width: 100%;
  text-align: left;
}

.second-box-content > form {
  width: 100%;
}

.formText {
  display: flex;
  flex-direction: row;
  margin: 25px 0;
}

.formText p {
  margin-left: 5px;
}

.form-icons {
  display: inline-block;
  height: 18px;
}

textarea {
  resize: none;
}

/* Forms Input CSS */

.sidebyside {
  display: flex;
  flex-direction: row;
  gap: 10%;
  justify-content: space-evenly;
  margin-right: 7%;
}

.form__group {
  position: relative;
  padding: 20px 0 0;
  width: 100%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: none;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 16px;
  color: #5a6581;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 17px;
  cursor: text;
  top: 20px;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 16px;
  color: #0f1a35;
  pointer-events: none;
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 600;
  border-width: 3px;
  border-image-slice: 1;
}

.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 17px;
  color: #0f1a35;
  font-weight: 700;
}

.form__field:required,
.form__field:invalid {
  box-shadow: none;
}

#message {
  padding: 20px 0;
  margin-top: 25px;
  width: 93%;
}

textarea {
  padding: 25px;
  max-height: fit-content;
}

form {
  > input[type="email"] {
    &:valid {
      color: red;
    }
  }
}

.sendBtn {
  float: right;
  margin-right: 7%;
  margin-top: 30px;
}

.sendBtn button {
  padding: 10px 30px;
}

@media (max-width: 768px) {
  .first-box {
    display: block;
    position: relative;
    height: fit-content;
    width: 80%;
    margin: 0;
    padding: 10%;
  }

  .second-box {
    display: flex;
    justify-content: center;
    position: relative;
    height: fit-content;
    width: 80%;
    margin: 0px 0px;
    padding: 10%;
  }

  .second-box-content {
    width: 100%;
    margin: 0;
  }

  .form__label {
    font-size: 14px !important;
  }

  .messagebox {
    padding: 0px !important;
  }
}

@media (max-width: 480px) {
  .form__label {
    font-size: 12px !important;
  }
}

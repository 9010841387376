@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600;700&display=swap');
* {
  font-family: 'Poppins', sans-serif;
  padding: 0;
  margin: 0;
}

.App{
  background-color: rgba(255, 255, 255, 0.6);
}

body {
  background-attachment: fixed;
  background-size: contain;
}

h1 {
  font-size: 46px;
  font-weight: 700;
}

h2 {
  font-size: 34px;
  font-weight: 600;
}

h3 {
  font-size: 20px;
  font-weight: 500;
}

h4 {
  font-size: 18px;
  font-weight: 500;
}

p {
  font-size: 18px;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 34px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 14.5px;
  }

  h4 {
    font-size: 13px;
  }

  p {
    font-size: 12px;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 12.5px;
  }

  h4 {
    font-size: 11px;
  }

  p {
    font-size: 11px;
  }
}

a {
  text-decoration: none;
}
button {
  border-radius: 20px;
  font-weight: 500;
  padding: 7px 20px;
  display: inline-block;
  margin: 0px 1rem;
  font-size: 18px;
}

button:hover{
  cursor: pointer;
}

.primary-btn {
  background-color: #0f1a35;
  color: white;
  border: solid #0f1a35 2px;
}

.secondary-btn {
  color: #0f1a35;
  background-color: white;
  border: 2px solid #0f1a35;
}

@media screen and (max-width: 768px) {
  button {
    margin: 0px 0.8rem;
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  button {
    margin: 0px 0.5rem;
    font-size: 12px;
  }
}

.product-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  width: 100%;
  height: 90vh;
  background-image: url("../../images/Products/productHeroImg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  z-index: 0;
}

.product-hero-title {
  height: fit-content;
  width: 100%;
  background-color: #0f1a35;
  color: #ffffff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0 2rem 0;
  position: relative;
  z-index: 0;
  /* margin-top: 7%; */
  top: 5rem;
}

.product-hero-title p {
  letter-spacing: 0.3rem;
}

.left-products {
  background-color: white;
  padding: 20px;
}

.right-products {
  background-color: #eaf4fa;
  padding: 20px;
}

.product-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-content > h2 {
  line-height: 120%;
  letter-spacing: 1.25px;
  display: inline;
}

.product-content > p {
  letter-spacing: 1.25px;
  display: inline;
}

.upper {
  display: flex;
  flex-direction: row;
  gap: 4rem;
  margin: 10px auto;
}

.productSections {
  width: 80%;
  margin: 20px auto;
}

.product-main-photo img {
  border-radius: 20px;
  height: 20rem;
}



/* Animation Heading */


.productsAnimateHeading{
	-webkit-animation: tracking-in-expand-fwd 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	animation: tracking-in-expand-fwd 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@-webkit-keyframes tracking-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px);
            transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px);
            transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}





/* Animation Subheadings */

.animateProductSubHeading{
  -webkit-animation: scale-in-hor-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: scale-in-hor-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}


@media (max-width: 1024px) {
  .product-hero {
    width: 100%;
    background-image: url(../../images/Products/productHeroImg1.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .left-products {
    padding: 10px 2px;
  }

  .right-products {
    padding: 10px 2px;
  }
  .upper {
    display: block;
    margin-bottom: 30px;
  }
  .product-main-photo img {
    display: none;
  }

  .product-content {
    text-align: center;
  }

  .productSections {
    width: 100%;
    padding: 10px 3px;
    margin: auto 0px;
  }

  .rec-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px !important;
    min-width: 25px !important;
    width: 25px !important;
    max-height: 25px !important;
    line-height: 0 !important;
  }

  .lower {
    max-width: 100vw;
  }
  .product-carousel {
    max-width: 100vw !important;
    margin: 0px !important;
  }
}

.full {
  display: flex;
  width: 100%;
  justify-content: center;
}

@-webkit-keyframes scroll {
  0% {
    transform: translateX(calc(-250px * 7));
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes scroll {
  0% {
    transform: translateX(calc(-250px * 7));
  }
  100% {
    transform: translateX(0);
  }
}

.slider {
  height: 100px;
  width: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  /* width: 960px; */
}
.slider::before,
.slider::after {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  -webkit-animation: scroll 40s linear infinite;
  animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 14);
}
.slider .slide {
  height: 100px;
  width: 250px;
}

.slide img {
  width: auto;
  height: 10rem;
}

@media screen and (max-width: 480px) {
  .slide img {
    height: 8rem;
  }
}
